(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/fraction-flow/assets/javascripts/middlewares-setup.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/fraction-flow/assets/javascripts/middlewares-setup.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  dialog
} = svs.components;
const {
  Confirm,
  branding,
  type,
  message
} = dialog;
const {
  accountBalance,
  paymentConfirm,
  spelpaus,
  timeLimits
} = svs.components.payment.middleware;
const {
  paymentDone,
  utils: paymentDoneUtils
} = svs.components.payment.middleware.paymentDone;
const spelpausMw = spelpaus(interactionContext => new Promise((resolve, reject) => {
  const defaultAction = [{
    title: 'Stäng',
    callback: () => {},
    showLoader: true
  }];
  const {
    text,
    title,
    icon,
    actions = defaultAction
  } = interactionContext.data.exclusion;
  dialog.api.add(new Confirm({
    branding: svs.components.dialog.branding.SPORT,
    type: svs.components.dialog.type.DEFAULT,
    icon,
    title,
    message: [{
      type: svs.components.dialog.message.TEXT,
      text
    }],
    actions: actions.map(action => [_objectSpread(_objectSpread({}, action), {}, {
      callback: () => {
        !action.shouldNotClose && svs.components.dialog.api.close();
        !action.shouldNotReject && reject();
        action.callback();
      }
    })][0])
  }));
}));
const timeLimitsMw = timeLimits(interactionContext => new Promise((resolve, reject) => {
  const {
    messages,
    title
  } = interactionContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'time-limit',
    title,
    message: [{
      type: message.TEXT,
      text: [messages[0], messages[1]].join('<br />')
    }],
    actions: [{
      title: 'Stäng',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject(new Error('Time limit exceeded'));
      }
    }, {
      title: 'Till mina gränser',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));

const accountBalanceMw = accountBalance(interactionContext => new Promise((resolve, reject) => {
  const {
    messages,
    title
  } = interactionContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'balance',
    title,
    message: [{
      type: message.TEXT,
      text: [messages[0], messages[1]].join('<br />')
    }],
    actions: [{
      title: 'Avbryt',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject();
      }
    }, {
      title: 'Sätt in',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));
const paymentConfirmMw = paymentConfirm(paymentContext => new Promise((resolve, reject) => {
  const {
    title,
    customMessage
  } = paymentContext;
  const dialogConfig = {
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'nav-games',
    title,
    actions: [{
      title: 'Avbryt',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject();
      }
    }, {
      title: 'Betala',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  };
  if (customMessage) {
    dialogConfig.message = [{
      type: message.TEXT,
      text: customMessage
    }];
  }
  dialog.api.add(new Confirm(dialogConfig));
}));
const paymentDoneSuccess = paymentDoneContext => new Promise(resolve => {
  const {
    title,
    message: dialogMessage,
    onCallback
  } = paymentDoneContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'thumbs-up',
    title,
    message: [{
      type: message.TEXT,
      text: dialogMessage
    }],
    actions: [{
      title: 'Okej',
      callback: () => {
        onCallback(paymentDoneUtils.TRACKING_ACTIONS.OK);
        dialog.api.close();
        resolve();
      },
      showLoader: true
    }]
  }));
});
const paymentDoneFailure = paymentDoneContext => {
  const {
    clientErrors,
    clientCode
  } = paymentDoneContext;
  svs.components.wagerPaymentFlow.paymentFailure.paymentFailureDialog(clientErrors, clientCode);
};
const paymentDoneMw = paymentDone(paymentDoneSuccess, paymentDoneFailure);
setGlobal('svs.components.wagerPaymentFlow.fractionFlow.middlewareSetup', {
  accountBalanceMw,
  paymentConfirmMw,
  paymentDoneMw,
  spelpausMw,
  timeLimitsMw
});

 })(window);