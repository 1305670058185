(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/fraction-flow/assets/javascripts/fraction-payment-flow.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/fraction-flow/assets/javascripts/fraction-payment-flow.js');
"use strict";


const {
  createPayment
} = svs.components.payment.payment;
const {
  marketplaceGroups
} = svs.components.payment.middleware;
const {
  authenticated
} = svs.components.payment.middleware.authenticated;
const {
  accountBalanceMw,
  spelpausMw,
  paymentConfirmMw,
  paymentDoneMw,
  timeLimitsMw
} = svs.components.wagerPaymentFlow.fractionFlow.middlewareSetup;
const {
  FractionService
} = svs.components.payment.serviceFraction;

const createFractionPaymentFlow = _ref => {
  let {
    config,
    fraction
  } = _ref;
  const paymentBuilder = createPayment(new FractionService(config)).addPayable(fraction).addPreMiddleware(authenticated).addPreMiddleware(spelpausMw).addPreMiddleware(timeLimitsMw).addPreMiddleware(accountBalanceMw).addPreMiddleware(marketplaceGroups).addPreMiddleware(paymentConfirmMw).addPostMiddleware(paymentDoneMw);
  return paymentBuilder;
};
setGlobal('svs.components.wagerPaymentFlow.fractionFlow.createFractionPaymentFlow', createFractionPaymentFlow);

 })(window);